import React from "react";
import styled from "styled-components";
import { colortheme, H, P } from "../../theme/Styles";
import Profile from "./Profile";
import profileImage from "./assets/profile.jpeg";
import { devices } from "../../theme/devices";

const Container = styled.div`
  padding-top: 5vw;
  background-color: ${colortheme.white};
`;

const TextContainer = styled.div`
  width: 80vw;
  margin: auto;
`;

const ProfileContainer = styled.div`
  width: 80vw;
  margin: 5vw auto;

  @media ${devices.mobile} {
    width: 100vw;
  }
`;

export default function ProfilesSection() {
  return (
    <Container>
      <TextContainer>
        <H>Lead Consultant</H>
      </TextContainer>
      <ProfileContainer>
        <Profile
          imageSrc={profileImage}
          name={"Professor Amal Kumarage"}
          title={"Founder and Lead Consultant"}
          description={
            "Professor Amal Kumarage, is recognized as one of the most accomplished transport professionals in Sri Lanka. He has over 20 years service as a consultant to international financing institutions such as the World Bank, ADB, SIDA in several countries across Asia. He has also been team leader in over 30 assignments carried out for both public and private sector organizations in Sri Lanka. He has served over 5 years as Chairman of the National Transport Commission and as a Board Member of the Road Development Authority and the Sri Lanka Transport Board. He has been an advisor to the Government of Sri Lanka and the Maldives on several occasions. He has also worked closely with private sector and professional institutions and as the Chairman of the Chartered Institute of Logistics and Transport and is the Founder President of Sri Lanka Society of Transport & Logistics. His academic achievement has not been any less noteworthy having published over 80 research papers, some of which have won awards. He is noted as a reformer of transport services and an innovator of both technology and service processes."
          }
        ></Profile>
      </ProfileContainer>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Landing from "./components/landing/Landing";
import Brand from "./components/brand/Brand";
import WorkSection from "./components/work/WorkSection";
import ProfilesSection from "./components/profiles/ProfilesSection";
import Contact from "./components/contact/Contact";
import Header from "./components/header/Header";

function App() {
  const [showHeader, setShowHeader] = useState(false);

  useEffect(() => {
    setShowHeader(window.pageYOffset > window.innerHeight - 10);
    window.onscroll = () => {
      setShowHeader(window.pageYOffset > window.innerHeight - 10);
    };
  }, []);

  return (
    <div>
      <Brand></Brand>
      {showHeader && <Header></Header>}
      <section id="home">
        <Landing></Landing>
      </section>
      <section id="services">
        <WorkSection></WorkSection>
      </section>
      <section id="leadership">
        <ProfilesSection></ProfilesSection>
      </section>
      <section id="contact">
        <Contact></Contact>
      </section>
    </div>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { colortheme, H, P } from "../../theme/Styles";
import logo from "./assets/logo.svg";

const Container = styled.div`
  position: absolute;
  top: 2vw;
  left: 10vw;
  z-index: 300;
  padding: 0.6vw 1vw;
  display: flex;
  background-color: ${colortheme.main};
`;

const Logo = styled.img`
  width: 10vw;
  height: auto;
  @media ${devices.mobile} {
    width: 18vw;
  }
`;

const Translog = styled(H)`
  font-size: 1.3vw;
  font-weight: 600;
  margin: 0;
  color: ${colortheme.dark};
  color: ${colortheme.white};
`;

const Consulting = styled(P)`
  margin: 0;
  font-size: 1.5vw;
`;

export default function Brand() {
  return (
    <Container>
      <Translog>TRANSLOG CONSULTING</Translog>
      {/* <Consulting>CONSULTING</Consulting> */}
    </Container>
  );
}

import styled from "styled-components";
import { devices } from "./devices";

export const colortheme = {
  main: "#2EABDC",
  dark: "#0073B2",
  white: "#ffffff",
  light: "#DDF5FF",
  black: "#082332",
};

export const H = styled.h1`
  font-family: "Jost", sans-serif;
  font-size: 3.5vw;
  font-weight: 500;
  text-align: left;
  line-height: 1.2;
  color: ${colortheme.black};
  @media ${devices.mobile} {
    font-size: 9.5vw;
  }
`;

export const P = styled.h3`
  font-family: "Jost", sans-serif;
  font-size: 1.5vw;
  font-weight: 400;
  text-align: left;
  line-height: 1.5;
  color: ${colortheme.black};

  @media ${devices.mobile} {
    font-size: 5vw;
  }
`;

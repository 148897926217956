import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P, colortheme } from "../../theme/Styles";
import logo from "./assets/background.svg";

const Container = styled.div`
  width: 100vw;
  right: 0;
  top: 0;
  padding: 0.8vw 0;
  background-image: linear-gradient(${colortheme.dark}, ${colortheme.dark});
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  position: fixed;
  z-index: 400;
  @media ${devices.mobile} {
    align-items: left;
    margin-top: 10vw;
    margin-bottom: 15vw;
    flex-direction: column-reverse;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  @media ${devices.mobile} {
    margin-top: 4vw;
    flex-direction: column;
    width: 32vw;
    height: 30vw;
    justify-content: space-between;
    margin-bottom: 15vw;
  }
`;

const Link = styled.div`
  border-bottom: 1px solid ${colortheme.white};
  padding: 0.1vw;
  margin-right: 2vw;
  cursor: pointer;
  /* transition: border 0.5s; */
  height: 1.8vw;
  color: ${colortheme.white};
  font-weight: 500;
  font-size: 1.2vw;
  @media ${devices.mobile} {
    height: 6vw;
    border-bottom: 1.4px solid ${colortheme.white};
  }

  &:hover {
    border-bottom: 2px solid ${colortheme.white};
    margin-right: 1.8vw;
    font-weight: 800;
  }
`;

export default function Header() {
  return (
    <Container>
      <LinkContainer>
        <a href="#home">
          <Link>Home</Link>
        </a>
        <a href="#services">
          <Link>Services</Link>
        </a>

        <a href="#leadership">
          <Link>Leadership</Link>
        </a>

        {/* <a href="#case_studies">
          <Link>Case Studies</Link>
        </a> */}

        <a href="#contact">
          <Link>Contact</Link>
        </a>
      </LinkContainer>
    </Container>
  );
}

import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P, colortheme } from "../../theme/Styles";
import logo from "./assets/background.svg";

const Container = styled.div`
  width: 100vw;
  background-color: ${colortheme.black};
  /* background-image: linear-gradient(${colortheme.dark}, ${colortheme.dark}); */
  overflow: hidden;
  padding-bottom: 5vw;
  @media ${devices.mobile} {
    margin-top: 10vw;
    margin-bottom: 15vw;
  }
`;

const TextContainer = styled.div`
  width: 80vw;
  margin: auto;
  padding-top: 5vw;
  @media ${devices.mobile} {
    width: 80vw;
    margin-left: 5vw;
    margin-top: -25vw;
  }
`;

const HeroText = styled(H)`
  color: ${colortheme.white};
  @media ${devices.mobile} {
    font-size: 12vw;
  }
`;

const TextTitle = styled(P)`
  margin: 0;
  font-weight: 500;
  font-size: 1.8vw;
  margin-top: 2vw;
  color: ${colortheme.white};
`;

const TextLine = styled(P)`
  margin: 0;
  color: ${colortheme.white};
`;

export default function Contact() {
  return (
    <Container>
      <TextContainer>
        <HeroText>Contact Us</HeroText>
        <TextTitle>Email:</TextTitle>
        <TextLine>translog.contact@gmail.com</TextLine>
        <TextTitle>Telephone:</TextTitle>
        <TextLine> +94 11 264 6702</TextLine>
        <TextLine> +94 77 302 4014</TextLine>

        <TextTitle>Fax:</TextTitle>
        <TextLine>+94 11 264 6702</TextLine>
        <TextTitle>Address:</TextTitle>
        <TextLine>No. 34, M.J.C. Fernando Mawatha, </TextLine>
        <TextLine>ldama, Moratuwa</TextLine>
        <TextLine>Sri Lanka</TextLine>
      </TextContainer>
    </Container>
  );
}

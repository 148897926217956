import React from "react";
import Project, { TextPositions } from "./Project";
import project1Image from "./assets/project1_banner.jpg";
import sparkImage from "./assets/spark.png";
import wizz from "./assets/wizz.jpg";
import styled from "styled-components";
import { H, P } from "../../theme/Styles";
import { devices } from "../../theme/devices";

const Container = styled.div`
  padding: 5vw 0;
  background-color: #f4f4f4;
`;

const TextContainer = styled.div`
  width: 80vw;
  margin: auto;
`;

const TextContent = styled.div`
  width: 50vw;
`;

export default function WorkSection() {
  return (
    <Container>
      <TextContainer>
        <H>What we do</H>
        <TextContent>
          <P>
            We possess a wide range of qualifications and experiences in
            airlines, airport, shipping, seaports, highways, traffic, public
            transport, railways, bus transport, taxi services, freight and
            logistics services. The core competencies at TransLog Consulting in
            each of these areas are:
          </P>

          <ul>
            <li>
              <P>
                Strategic Planning to align resources to specific goals and
                objectives
              </P>
            </li>
            <li>
              <P>User and performance surveys to determine and measure KPls</P>
            </li>
            <li>
              <P>
                Demand forecasting to estimate growth under diverse scenarios
              </P>
            </li>
            <li>
              <P>Supply Chain Management to improve efficiency in delivery</P>
            </li>
            <li>
              <P> Tech-based solutions for Public Transport operations</P>
            </li>
            <li>
              <P>
                Education and Training in all aspects of transport and logistics
              </P>
            </li>
            <li>
              <P>Feasibility studies for infrastructure projects</P>
            </li>
            <li>
              <P>
                {" "}
                Design of physical facilities such as car parks, warehouses, bus
                terminals and roads
              </P>
            </li>
            <li>
              <P>Traffic studies and analysis for local and urban areas</P>
            </li>
            <li>
              <P>Traffic Impact Assessment Reports for building approvals</P>
            </li>
            <li>
              <P>
                Operational efficiency studies for improving functions and
                processes{" "}
              </P>
            </li>
            <li>
              <P>Fleet Scheduling and Management to optimize vehicle use</P>
            </li>
            <li>
              <P>Vehicle and fleet operating costing</P>
            </li>
            <li>
              <P>Fleet Scheduling and Management to optimize vehicle use </P>
            </li>

            <li>
              <P>Business Planning for transport and logistics services </P>
            </li>

            <li>
              <P>Transport and road safety studies and research </P>
            </li>

            <li>
              <P>Urban transport planning</P>
            </li>
          </ul>
        </TextContent>
      </TextContainer>
    </Container>
  );
}

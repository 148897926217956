import React from "react";
import styled from "styled-components";
import { devices } from "../../theme/devices";
import { H, P, colortheme } from "../../theme/Styles";
import logo from "./assets/background.svg";

const Container = styled.div`
  z-index: 200;
  width: 100vw;
  min-height: 50vw;
  height: 100vh;
  /* background-color: #f3fffe; */
  background-image: linear-gradient(${colortheme.dark}, ${colortheme.dark});
  display: flex;
  align-items: center;
  overflow: hidden;
  @media ${devices.mobile} {
    align-items: left;
    margin-top: 10vw;
    margin-bottom: 15vw;
    flex-direction: column-reverse;
  }
`;

const TextContainer = styled.div`
  width: 50vw;
  margin: auto;
  padding-top: 5vw;
  @media ${devices.mobile} {
    width: 80vw;
    margin-left: 5vw;
    margin-top: -25vw;
  }
`;

const HeroText = styled(H)`
  color: ${colortheme.white};
  @media ${devices.mobile} {
    font-size: 12vw;
  }
`;

const Description = styled(P)`
  color: ${colortheme.white};
`;

const HeroImageContainer = styled.div`
  width: 30vw;
  @media ${devices.mobile} {
    width: 100vw;
  }
`;

const HeroImage = styled.img`
  width: auto;
  max-height: 90vh;
  margin: auto;
  @media ${devices.mobile} {
    width: 125vw;
    max-height: 85vw;
    margin: auto;
    transform: rotate(-20deg);
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3.5vw;
  @media ${devices.mobile} {
    margin-top: 4vw;
    flex-direction: column;
    width: 32vw;
    height: 30vw;
    justify-content: space-between;
    margin-bottom: 15vw;
  }
`;

const Link = styled.div`
  border-bottom: 1px solid ${colortheme.white};
  padding: 0.1vw;
  margin-right: 2vw;
  cursor: pointer;
  /* transition: border 0.5s; */
  height: 2vw;
  color: ${colortheme.white};
  font-weight: 700;
  font-size: 1.2vw;

  @media ${devices.mobile} {
    height: 6vw;
    border-bottom: 1.4px solid ${colortheme.white};
  }

  &:hover {
    border-bottom: 2px solid ${colortheme.white};
    margin-right: 1.8vw;
    font-weight: 800;
  }
`;

export default function Landing() {
  return (
    <Container>
      <TextContainer>
        <HeroText>
          Professional Consultancy Solutions from Transport and Logistics
          Experts
        </HeroText>
        <Description>
          TransLog Consulting (TLC) is an emerging firm based in Sri Lanka
          specializing in providing niche consulting solutions for the transport
          and logistics sector. Our expertise range from policy and planning
          studies to institutional capacity building, transport and market
          surveys, feasibility studies and finding tech-based solutions for
          operational issues.
        </Description>

        <LinkContainer>
          <a href="#services">
            <Link>Services</Link>
          </a>

          <a href="#leadership">
            <Link>Leadership</Link>
          </a>

          {/* <a href="#case_studies">
            <Link>Case Studies</Link>
          </a> */}

          <a href="#contact">
            <Link>Contact</Link>
          </a>
        </LinkContainer>
      </TextContainer>
      <HeroImageContainer>
        {/* <HeroImage src={logo}></HeroImage> */}
      </HeroImageContainer>
    </Container>
  );
}
